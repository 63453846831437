import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Routes, Navigate, Route} from 'react-router-dom'
import * as auth from '../core/_redux'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(auth.actions.logout())
    document.location.reload()
  }, [dispatch])

  return (
    <Routes>
        <Route index element={<Navigate to='/auth/login' />} />
    </Routes>
  )
}
