import {Dispatch, SetStateAction} from 'react'
import {AssemblyState} from "../../../app/modules/assembly/core/_models";

export type ID = undefined | null | number

export type Entity = {
  id: ID
  name?: string
  created_at: Date
  updated_at: Date
}

export type PaginationState = {
  current_page: number
  last_page?: number
  per_page: number,
  take?: number,
  skip?:number,
  links?: Array<{label: string; active: boolean; url: string | null}>
}
export type PaginationResponse = {
  current_page: number
  from: number
  to: number
  last_page: number
  per_page: number,
  first_page_url: string
  last_page_url: string
  next_page_url: string | null
  prev_page_url: string | null
  path: string
  links?: Array<{label: string; active: boolean; url: string | null}>
}

export type CustomDataState  = {
  [key: string]: any;
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
  select?: Array<number>
  from?: Date
  to?: Date
}

export type SearchState = {
  search?: string
}

export type ResponseData<T,S = any> = PaginationResponse & {
  data: T,
  additionalData: S
}

export type ResponseList<T,S = any> = {
  result: ResponseData<T,S>
  status: string
  message: string | null
}

export type Response<T> = {
  result: T
  status: string
  message: string | null
}

export type QueryState = {[key: string]: any} & PaginationState & SortState & FilterState & SearchState & AssemblyState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void,
  customData:CustomDataState,
  updateCustomData: (data:CustomDataState ) => void
}



export const initialQueryState: QueryState = {
  go_top: false,
  current_page: 1,
  per_page: 10, //not sure if is the best thin
  links: [],
}


export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
  customData: {},
  updateCustomData: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: ResponseData<T> | undefined
  refetch: () => void,
  isRefetching: boolean,
  isLoading: boolean
  query: string,
  customData:CustomDataState,
  queryFunction:(query: string,customData:CustomDataState) => Promise<ResponseData<any[]>> | void,
}

export const initialQueryResponse = {
  refetch: () => {
  },
  isLoading: false,
  query: '',
  isRefetching: false,
  customData: {params: {}},
  queryFunction: () => {}
}

export type ListViewData = {
  [key:number]: {
    id:ID,
    [key:string]:any
  }[]
}

export type ListViewContextProps = {
  selected: ListViewData
  isSelected: (selectedId: ID) => boolean,
  onSelect: (selectedId: ID,isShiftPressed?:boolean) => void
  onShift: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemForUpdate?: Entity | null
  setItemForUpdate: Dispatch<SetStateAction<Entity | null>>
  itemAction: string
  setItemAction: Dispatch<SetStateAction<string>>
  isAllSelected: boolean
  disabled: boolean,
  lastClicked: ID
}

export const initialListView: ListViewContextProps = {
  selected: {},
  isSelected: () => false,
  onSelect: () => {},
  onSelectAll: () => {},
  onShift: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemForUpdate: () => {},
  itemAction: '',
  setItemAction: () => {},
  isAllSelected: false,
  disabled: false,
  lastClicked:null
}
