import {Action, createSlice} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {getUserByToken} from './_requests'
import {UserModel, UserPermissionModel} from "../../users/core/_models";


export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    Login: 'auth/login',
    Logout: 'auth/logout',
    Register: 'auth/register',
    UserRequested: 'auth/requestUser',
    UserLoaded: 'auth/fulfillUser',
    SetUser: 'auth/setUser',
    SetRoles: 'auth/setRoles',
    SetPermissions: 'auth/setPermissions',
    SetLastUpdated: 'auth/setLastUpdated',
}

const initialAuthState: IAuthState = {
    user: undefined,
    roles: [],
    permissions: [],
    accessToken: undefined,
    lastUpdated: undefined
}

export interface IAuthState {
    user?: UserModel,
    roles:number[],
    permissions:UserPermissionModel,
    accessToken?: string,
    lastUpdated?: Date
}


export const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        login: (state , action: ActionWithPayload<IAuthState>) => {
            const accessToken = action.payload?.accessToken
            return {accessToken, user: undefined, roles: [], permissions: []}
        },
        register: (state, action: ActionWithPayload<IAuthState>) => {
            const accessToken = action.payload?.accessToken
            return {accessToken, user: undefined, roles: [], permissions: []}
        },
        logout: (state, action: ActionWithPayload<IAuthState>) => {
            return initialAuthState
        },
        requestUser: (state, action: ActionWithPayload<IAuthState>) => {
            return {...state, user: undefined}
        },
        fulfillUser: (state, action: ActionWithPayload<IAuthState>) => {
            const user = action.payload ? action.payload.user : undefined;
            const roles = action.payload ? action.payload.roles : [];
            const permissions = action.payload ? action.payload.permissions : []

            return {...state, user,roles,permissions}
        },
        setUser: (state, action: ActionWithPayload<IAuthState>) => {
            const user = action.payload ? action.payload.user : undefined;

            return {...state, user}
        },
        setRoles: (state, action: ActionWithPayload<IAuthState>) => {
            const roles = action.payload ? action.payload.roles : [];

            return {...state, roles}
        },
        setPermissions: (state, action: ActionWithPayload<IAuthState>) => {
            const permissions = action.payload ? action.payload.permissions : []

            return {...state, permissions}
        },

        setLastUpdated: (state, action: ActionWithPayload<Date>) => {
            const lastUpdated = action.payload ? action.payload : new Date();

            return {...state, lastUpdated}
        },
    }
});


export const actions = {
    login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
    register: (accessToken: string) => ({
        type: actionTypes.Register,
        payload: {accessToken},
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: () => ({
        type: actionTypes.UserRequested,
    }),
    fulfillUser: (user: UserModel, roles: number[], permissions: UserPermissionModel) => ({type: actionTypes.UserLoaded, payload: {user,roles,permissions}}),
    setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
    setRoles: (roles: number[]) => ({type: actionTypes.SetRoles, payload: {roles}}),
    setPermissions: (permissions: UserPermissionModel) => ({type: actionTypes.SetPermissions, payload: {permissions}}),
    setLastUpdated: (date: Date) => ({type:actionTypes.SetLastUpdated, payload: date})
}

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const result:IAuthState = yield getUserByToken();
        yield put(actions.fulfillUser(result.user as UserModel,result.roles,result.permissions))
        yield put(actions.setLastUpdated(new Date()));
    })
}
