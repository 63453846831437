import {Action, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {createEntityStruct, EntityStruct, IdItem} from "../../../../setup/redux/utils/createEntityStruct";

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    Set: 'alert/set',
    Add :'alert/add',
    Remove :'alert/remove',
    RemoveAll:'alert/removeAll'
}

export type IAlertState = {
    type:string,
    title:string,
    message:string,
} & IdItem


export const alertSlice = createSlice({
    name: 'alert',
    initialState:  createEntityStruct<IAlertState & IdItem>([]),
    reducers: {
        add: (state, action: PayloadAction<IAlertState & IdItem>) => {
            state.byId[action.payload.id] = action.payload;
        },
        set: (state, action: ActionWithPayload<EntityStruct<IAlertState & IdItem>>) => {
            return action.payload;
        },
        remove: (state, action: PayloadAction<string>) => {
            const id = action.payload;
            delete state.byId[id];
        },
        removeAll: (state, action: ActionWithPayload<EntityStruct<IAlertState & IdItem>>) => {
            return action.payload;
        }
    }
});

export const actions = {
    setAlert: (alert: EntityStruct<IAlertState & IdItem>) => ({type: actionTypes.Set, payload: {alert}}),
    addAlert: (alert: IAlertState & IdItem) => ({type: actionTypes.Add, payload: alert}),
    removeAlert: (id:string) => ({type: actionTypes.Remove, payload: id}),
    removeAll: () => ({type: actionTypes.RemoveAll, payload:  createEntityStruct<IAlertState & IdItem>([])}),
}




export function* saga() {

}
