import {AppThunkCreator} from "../../../../../setup/redux/Store";

import {actions, INotification} from "../NotificationRedux"
import {VariantType} from "notistack";
import Button from "@mui/material/Button";
import React from "react";


export type Notification  = {
    message:string,
    status:VariantType
}

export const sendNotification: AppThunkCreator<Notification> = ({message,status}:Notification) => (dispatch, getState) => {
    const enqueueSnackbar = (notification: INotification) => dispatch(actions.addNotification(notification));
    const closeSnackbar = (key:string = '') => dispatch(actions.closeNotification(key));
    const dismissButton = (key:string = '') => React.createElement(Button,{
        style: { color: "lightgrey" },
        onClick: () => closeSnackbar(key),
    },'Zavřít');
    enqueueSnackbar({
        message,
        key: new Date().getTime() + Math.random(),
        dismissed:false,
        options: {
            variant: status,
            action: key => dismissButton(key)
        },
    });

}