import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, ListViewData, QueryResponseContextProps, QueryState} from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
    return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
    return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&per_page=10&sort=id&order=desc&search=a&filter[name]=a&filter[online]=false
function stringifyRequestQuery(state: QueryState): string {
    const pagination = qs.stringify({page:state.current_page,...state}, {filter: ['page', 'per_page','take','skip'], skipNulls: true})
    const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
    const search = isNotEmpty(state.search)
        ? qs.stringify(state, {filter: ['search'], skipNulls: true})
        : ''

    const filter = state.filter
        ? Object.entries(state.filter as any)
            .filter((obj) => isNotEmpty(obj[1]))
            .map((obj) => {
                return `filter[${obj[0]}]=${obj[1]}`
            })
            .join('&')
        : ''

    const assembly = qs.stringify(state, {filter: [
            'compare',
            'cheaper_view',
            'cheaper_other',
            'mark_discount',
            'with_ignored',
            'catalog_diff',
            'no_diff_plus',
            'no_diff_zero',
            'no_diff_minus',
            'no_diff_delivery_plus',
            'no_diff_delivery_zero',
            'no_diff_delivery_minus',
            'ordering',
            'no_auto_partners',
            'no_manual_partners',
            'calendar_date'
        ], skipNulls: true})

    return [pagination, sort, search, filter, assembly]
        .filter((f) => f)
        .join('&')
        .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
    const cache: unknown = qs.parse(query)
    return cache as QueryState
}

function calculatedGroupingIsDisabled(isLoading: boolean, data: {id: ID}[]
    | undefined): boolean {
    if (isLoading) {
        return true
    }

    return !data || data.length === 0
}

function calculateIsAllDataSelected<T extends {id:ID}>(
    data: Array<T>,
    selected: Array<T>,
    compareById: boolean = false
): boolean {
    if (!data) {
        return false
    }


    return compareById ?
        data.length > 0 && data.every(item => selected.some(item1 => item1.id === item.id)) :
        data.length > 0 && (data.length === selected.length)

}

function groupingOnSelect(
    id: ID,
    selected: ListViewData,
    setSelected: Dispatch<SetStateAction<ListViewData>>,
    lastClicked: ID,
    setLastClicked: Dispatch<SetStateAction<ID>>,
    isShiftPressed:boolean|undefined,
    currentPage: number,
    data: ListViewData,
) {
    if (!id) {
        return
    }
    const additionalData = currentPage in data ? data[currentPage].find(item => item.id === id) : {};
    const existingId = currentPage in selected && selected[currentPage].find(item => item.id === id) !== undefined
    if(isShiftPressed) {
        //if no rows selected set first row as last clicked
        const lastClickedTicked = lastClicked ? currentPage in selected && selected[currentPage].find(item => item.id === lastClicked) !== undefined : true;
        const lastClickedIndex = lastClicked ? (currentPage in data ? data[currentPage].findIndex(item => item.id === lastClicked) : null) : 0;
        const clickedIndex = currentPage in data ? data[currentPage].findIndex(item => item.id === id) : null;

        let dataBetween:{
            id:ID,
            [key:string]:any
        }[] = [];


        if(lastClickedIndex !== null && clickedIndex !== null && clickedIndex !== lastClickedIndex) {
            //include last element, exclude first element
            const directionUp = clickedIndex < lastClickedIndex
           dataBetween = data[currentPage].slice(
               Math.min(clickedIndex,lastClickedIndex) + (directionUp || lastClicked == null ? 0 : 1),
               Math.max(clickedIndex,lastClickedIndex) + (directionUp ? 0 : 1)
           )
        }


        const selectedIds = lastClickedTicked ?
            [
                ...(currentPage in selected ? selected[currentPage] : []),
                ...dataBetween.filter((item) => !selected[currentPage].some(item1 => item.id === item1.id)),
            ]:
            selected[currentPage].filter((item) => !dataBetween.some(item1 => item.id === item1.id))

        setSelected(state => ({
            ...state,
            [currentPage]:selectedIds
        }))


        setLastClicked(!lastClickedTicked ? selectedIds.at(-1)?.id : id)


    } else {
        const selectedIds = existingId ?
            selected[currentPage].filter((item) => item.id !== id) :
            [
                ...(currentPage in selected ? selected[currentPage] : []),
                {...additionalData,id},
            ]

        setSelected(state => ({
            ...state,
            [currentPage]:selectedIds
        }))
        setLastClicked(id)
    }


}

function isSelected(id:ID, selected: ListViewData, currentPage:number) {
    return currentPage in selected && (selected[currentPage].find(item => item.id === id) !== undefined)
}


function groupingOnSelectAll(
    isAllSelected: boolean,
    setSelected: Dispatch<SetStateAction<ListViewData>>,
    currentPage: number,
    data?: ListViewData,

) {
    if (isAllSelected) {
        setSelected(state => ({
            ...state,
            [currentPage]:[]
        }));
        return
    }



    if (!data || Object.keys(data).length === 0) {
        return
    }

    // Remove ids of current page and transform Data Type to array of Ids
    setSelected(data);
}

function groupingOnShift(
    id: ID,
    selected: ListViewData,
    setSelected: Dispatch<SetStateAction<ListViewData>>,
    lastClicked: ID,
    setLastClicked: Dispatch<SetStateAction<ID>>,
    currentPage:number,
    data?: ListViewData
) {
    if (!id || !data || Object.keys(data).length === 0) {
        return
    }

}

// Hook
function useDebounce(value: string | undefined, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay] // Only re-call effect if value or delay changes
    )
    return debouncedValue
}

export {
    createResponseContext,
    stringifyRequestQuery,
    parseRequestQuery,
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    groupingOnSelectAll,
    groupingOnShift,
    isSelected,
    useDebounce,
    isNotEmpty,
}
