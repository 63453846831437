import {actions, IAuthState} from "../../app/modules/auth";
import {
    GET_USER_BY_ACCESSTOKEN_URL,
    getUserByToken,
    USER_REFRESH_REQUEST_SECONDS
} from "../../app/modules/auth/core/_requests";
import {UserModel} from "../../app/modules/users/core/_models";

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
     async (config: any) => {

         const {
             auth: {accessToken,lastUpdated},
         } = store.getState()

         if (accessToken) {
             config.headers.Authorization = `Bearer ${accessToken}`

             const now = new Date();

             const lastUpdatedInSeconds = lastUpdated === undefined ? Infinity : Math.abs((now.getTime() - lastUpdated) / 1000);

             if (GET_USER_BY_ACCESSTOKEN_URL !== config.url && lastUpdatedInSeconds > USER_REFRESH_REQUEST_SECONDS) {
                 const result: IAuthState = await getUserByToken();

                 const {dispatch} = store;

                 dispatch(actions.fulfillUser(result.user as UserModel, result.roles, result.permissions));
                 dispatch(actions.setLastUpdated(new Date()));

                // throw new axios.Cancel('Operation canceled by the user.');

             }

         }

         return config
     },
    (err: any) => Promise.reject(err)
  )
}
