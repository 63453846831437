import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {reduxBatch} from '@manaflair/redux-batch'
import {PersistConfig, persistReducer, persistStore} from 'redux-persist'
import {rootReducer, rootSaga, RootState} from './RootReducer'
import storage from "redux-persist/lib/storage";

const sagaMiddleware = createSagaMiddleware()

const persistConfig: PersistConfig<RootState> = {
    key: 'styleplus-statistics',
    storage,
    whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
    }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [reduxBatch],
});

export type AppDispatch = typeof store.dispatch
export type AppThunk<R = void> = (dispatch: AppDispatch, getState: () => RootState) => R;
export type AppThunkCreator<T = void, R = void> = (a: T) => AppThunk<R>;

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store
