import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const actionTypes = {
    Add :'revaluationCountry/add',
    Remove :'revaluationCountry/remove',
}

const defaultState:number = 1

export const revaluationCountrySlice = createSlice({
    name: 'revaluationCountry',
    initialState:  defaultState,
    reducers: {
        add: (state, action: PayloadAction<number>) => {
            return  action.payload;
        },
        remove: (state) => {
            return 1;
        },
    }
});

export const actions = {
    addRevaluationCountry: (countryRevaluationId: number) => ({type: actionTypes.Add, payload: countryRevaluationId}),
    removeRevaluationCountry: () => ({type: actionTypes.Remove}),
}




export function* saga() {

}
