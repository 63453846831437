import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em'
        }
      }
    }
  }
});

export default defaultTheme;
 

