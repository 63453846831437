import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SnackbarKey, VariantType} from "notistack";


export const actionTypes = {
    ENQUEUE_SNACKBAR: 'notifications/add',
    CLOSE_SNACKBAR :'notifications/close',
    REMOVE_SNACKBAR :'notifications/remove',
}


export type INotification = {
    message:string,
    key: SnackbarKey,
    dismissed:boolean,
    options: {
        variant: VariantType | undefined,
        action: (key:string) => {}
    }
}


const defaultState:INotification[] = [];



export const notificationSlice = createSlice({
    name: 'notifications',
    initialState:  defaultState,
    reducers: {
        add: (state, action: PayloadAction<{key:SnackbarKey, notification:INotification}>) => {
            return [
                ...state,
                {
                    ...action.payload.notification,
                    key: action.payload.key,
                }
            ];
        },
        close: (state, action: PayloadAction<{dismissAll: boolean, key: SnackbarKey}>) => {
            return state.map(notification => (
                    (action.payload.dismissAll || notification.key === action.payload.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ))

        },
        remove: (state, action: PayloadAction<SnackbarKey>) => {
            return state.filter(
                    notification => notification.key !== action.payload,
                )

        },
    }
});

export const actions = {
    addNotification: (notification: INotification) => {
        const key = notification && notification.key;
        return {
            type: actionTypes.ENQUEUE_SNACKBAR,
            payload: {
                notification: {
                    ...notification
                },
                key: key || new Date().getTime() + Math.random()}
        }
    },
    closeNotification: (key: SnackbarKey) => ({type: actionTypes.CLOSE_SNACKBAR, payload: {dismissAll: !key, key}}),
    removeNotification: (key:SnackbarKey) => ({type: actionTypes.REMOVE_SNACKBAR, payload: key}),
}


export function* saga() {

}
