import { toIndexedObject } from './toIndexedObject';

export type IdItem = {
    id: string;
}

export type EntityStruct<T extends IdItem> = {
	byId: { [id: string]: T };
};

/**
 * Creates entity structure with indexed object from given array
 * @param init Array of Entities
 */
export const createEntityStruct = <T extends IdItem>(init: T[] = []): EntityStruct<T> => ({
	byId: toIndexedObject(init) as { [id: string]: T },
});
