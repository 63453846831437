import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {UserModel} from "../../users/core/_models";

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      id: 1,
      name: 'admin',
      password: 'demo',
      email: 'admin@demo.com',
      is_system: true,
      is_active: true,
      per_page: 10,
      created_by: 1,
      updated_by: 1,
      auth: {
        accessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
        refreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
      roles: [1], // Administrator
      pic: toAbsoluteUrl('/media/avatars/150-2.jpg'),
      language: 'en',
    },
    {
      id: 2,
      name: 'user',
      password: 'demo',
      email: 'user@demo.com',
      is_system: true,
      is_active: true,
      per_page: 10,
      created_by: 1,
      updated_by: 1,
      auth: {
        accessToken: 'access-token-6829bba69dd3421d8762-991e9e806dbf',
        refreshToken: 'access-token-f8e4c61a318e4d618b6c199ef96b9e55',
      },
      roles: [2], // Manager
      pic: toAbsoluteUrl('/media/avatars/150-1.jpg'),
      language: 'en',
    },
    {
      id: 3,
      name: 'guest',
      password: 'demo',
      email: 'guest@demo.com',
      is_system: true,
      is_active: true,
      per_page: 10,
      created_by: 1,
      updated_by: 1,
      auth: {
        accessToken: 'access-token-d2dff7b82f784de584b60964abbe45b9',
        refreshToken: 'access-token-c999ccfe74aa40d0aa1a64c5e620c1a5',
      },
      roles: [3], // Guest
      pic: toAbsoluteUrl('/media/avatars/150-5.jpg'),
      language: 'en',

    },
  ]
}
