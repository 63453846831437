import MockAdapter from 'axios-mock-adapter'
import {
  LOGIN_URL,
  GET_USER_BY_ACCESSTOKEN_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
} from '../core/_requests'
import {UsersTableMock} from './usersTableMock'
import {ID} from "../../../../_metronic/helpers";
import {UserModel} from "../../users/core/_models";

export function mockAuth(mock: MockAdapter) {
  mock.onPost(LOGIN_URL).reply(({data}) => {
    const {email, password} = JSON.parse(data)

    if (email && password) {
      const user = UsersTableMock.table.find(
        (x) => x.email.toLowerCase() === email.toLowerCase() && x.password === password
      )

      if (user) {
        const auth = user.auth
        return [200, {...auth, password: undefined}]
      }
    }

    return [400]
  })

  mock.onPost(REGISTER_URL).reply(({data}) => {
    const {email, name, password} = JSON.parse(data)

    if (email && name && password) {
      const user: UserModel = {
        id: generateUserId(),
        email,
        name: name,
        password,
        is_system: true,
        is_active: true,
        per_page: 10,
        created_by: 1,
        updated_by: 1,
        roles: [2], // Manager
        auth: {
          accessToken: 'access-token-' + Math.random(),
          refreshToken: 'access-token-' + Math.random(),
        },
        pic: process.env.PUBLIC_URL + '/media/users/default.jpg',
      }

      UsersTableMock.table.push(user)
      const auth = user.auth

      return [200, {...auth, password: undefined}]
    }

    return [400]
  })

  mock.onPost(REQUEST_PASSWORD_URL).reply(({data}) => {
    const {email} = JSON.parse(data)

    if (email) {
      const user = UsersTableMock.table.find((x) => x.email.toLowerCase() === email.toLowerCase())
      let result = false
      if (user) {
        user.password = undefined
        result = true
        return [200, {result, password: undefined}]
      }
    }

    return [400]
  })

  mock.onGet(GET_USER_BY_ACCESSTOKEN_URL).reply(({headers: {Authorization}}) => {
    const accessToken =
      Authorization && Authorization.startsWith('Bearer ') && Authorization.slice('Bearer '.length)

    if (accessToken) {
      const user = UsersTableMock.table.find((x) => x.auth?.accessToken === accessToken)

      if (user) {
        return [200, {...user, password: undefined}]
      }
    }

    return [401]
  })

  function generateUserId(): ID {
    const ids = UsersTableMock.table.map((el) => el.id ? el.id : 0)
    const maxId = Math.max(...ids)
    return maxId + 1
  }
}
