import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {SnackbarKey, useSnackbar} from 'notistack';
import {INotification} from "../NotificationRedux";
import {notificationsSelector} from "../../../../../setup/redux/selectors/notificationsSelector";
import {removeNotification} from "../actions/removeNotification";

let displayed:SnackbarKey[] = [];

export const useNotifier = () => {
    const dispatch = useDispatch();
    const notifications: Array<INotification>  = useSelector(notificationsSelector);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id:SnackbarKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id:SnackbarKey) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({ key , message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(<span dangerouslySetInnerHTML={{__html:message}} />, {
                key,
                ...options,
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeNotification(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};