import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Navigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../../core/_requests'
import {useIntl} from 'react-intl'
import {VariantType} from "notistack";
import {sendNotification} from "../../../notifications/redux/actions/sendNotification";
import {useDispatch} from "react-redux";
import {Alert} from "../../../alerts";

const initialValues = {
  email: '',
  password:'',
  password_confirmation:''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  password_confirmation: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export function PasswordReset() {
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch();
  const { token } = useParams();
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        resetPassword(values.email, token ,values.password, values.password_confirmation )
          .then(result => {
            setLoading(false)
            const notification = {
              message:result.message ? result.message : '',
              status:result.status.toLowerCase() as VariantType
            };
            dispatch(sendNotification(notification))
            setResetPasswordSuccess(true);
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)

           const {response:{data:{message}}} = err;

            const notification = {
              message:message ? message : '',
              status:'error' as VariantType
            };
            dispatch(sendNotification(notification))
          })
      }, 1000)
    },
  })

  if(resetPasswordSuccess) return <Navigate to='/login' />

  return !token || token.length === 0 ?
      <Alert id={'resetPasswordTokenMissing'} type='danger' title='Token not valid' message="Password reset token is not valid." /> : (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.RESET.TITLE'})}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.RESET.DESC'})}
          </div>
          {/* end::Link */}
        </div>


        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          </label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.NEW_PASSWORD'})}
          </label>
          <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
              )}
          />
          {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
          </label>
          <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password_confirmation')}
              className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation},
                  {
                    'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
                  }
              )}
          />
          {formik.touched.password_confirmation && formik.errors.password_confirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password_confirmation}</span>
                </div>
              </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {loading && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'AUTH.GENERAL.PLEASE_WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({id: 'AUTH.REGISTER.CANCEL'})}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
