import axios, {AxiosResponse} from 'axios'
import {_models} from './_models'
import {IProfileDetails, IUpdatePassword} from "../../accounts/core/_models";
import {IAuthState} from "./_redux";
import {Response} from "../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const CHANGE_EMAIL = `${API_URL}/auth/change-email`
export const UPDATE_USER = `${API_URL}/auth/update-user`
export const UPDATE_PASSWORD = `${API_URL}/auth/update-password`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const USER_REFRESH_REQUEST_SECONDS = 60;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

export function changeEmail(email: string, password: string) {
    return axios.post(CHANGE_EMAIL, {email, password})
}


export function updateProfile(data:IProfileDetails) {
    return axios.post(UPDATE_USER, data);
}


export function changePassword(data:IUpdatePassword) {
    return axios.post(UPDATE_PASSWORD, data);

}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<_models>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function resetPassword(email: string, token:string|undefined, password:string,password_confirmation:string): Promise<Response<any>> {
    return axios
        .post(RESET_PASSWORD_URL, {email,token,password,password_confirmation})
        .then((response: AxiosResponse) => response.data)
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
    return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
        .then(res => {
            return res.data;
        })
        .then(data => {
            const authState:IAuthState= data.result;
            return authState;

        });
}
