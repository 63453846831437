const QUERIES = {
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  CURRENCIES_LIST: 'currencies-list',
  COUNTRIES_LIST: 'countries-list',
  PERMISSIONS_LIST: 'permissions-list',
  DOWNLOADERS_LIST: 'downloaders-list',
  PARSERS_LIST: 'parsers-list',
  REVALUATION_LIST: 'revaluation-list',
  PACKAGE_RUN_INFO:'package-run-info',
  ITEM_SERVER_LINKS:'item-server-links-list',
  ASSEMBLY_LIST: 'assembly-list',
  SERVERS_LIST: 'servers-list',
  CALENDAREVENTS_LIST: 'calendarEvents-list',
  CONCURENCYGROUPS_LIST: 'concurrencyGroups-list',
  COMPANIES_LIST: 'companies-list',
  CONCURENCIES_LIST: 'concurrencies-list',
  IGNORED_ITEMS_LIST: 'ignored-items-list',
  CONCURENCIES_LINKED_NAMES_LIST: 'concurency-linked-names-list',
  PACKAGES_LIST: 'packages-list',
  PRODUCERS_LIST: 'producers-list',
  ITEMS_LIST: 'items-list',
  DETAIL_LIST: 'detail-list',
  REPORTS_LIST: 'reports-list'
}

export {QUERIES}
