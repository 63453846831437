import React, {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from '../core/_redux'
import {getUserByToken} from '../core/_requests'
import {RootState} from '../../../../setup'
import {addAlert} from "../../../../setup/redux/actions/addAlert";
import {Alert, IAlertState} from "../../alerts";
import {alertSelector} from "../../../../setup/redux/selectors/alertSelector";
import {UserModel} from "../../users/core/_models";

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
  const authAlert: IAlertState = useSelector((state:RootState) => alertSelector(state,'auth'), shallowEqual);

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const result = await getUserByToken();

          dispatch(props.fulfillUser(
              result.user as UserModel,
              result.roles,
              result.permissions));

            dispatch(props.setLastUpdated(new Date()));
        }
      } catch (error) {
          console.log(error);
          dispatch(addAlert({
              id:'auth',
              type:'danger',
              title:'Request failed',
              message: (error as Error).message
          }))

        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser();
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])


  return showSplashScreen ? <LayoutSplashScreen /> : (<>
      {authAlert ? <Alert id={authAlert.id} type={authAlert.type} title={authAlert.title} message={authAlert.message} /> : null}
      {props.children}
  </>)
}

export default connector(AuthInit)
