import React from "react";
import {KTSVG} from "../../../../_metronic/helpers";
import {IdItem} from "../../../../setup/redux/utils/createEntityStruct";
import {useDispatch} from "react-redux";
import {removeAlert} from "../../../../setup/redux/actions/removeAlert";


type Props = {
    type:string,
    title:string,
    message:string,
    closable?:boolean
} & IdItem;




const getIcon = (type:string) => {
    const icons: {[index: string]:any} = {
        success: 'gen043.svg',
        danger: 'gen040.svg',
        warning: 'gen044.svg',
        info: 'gen045.svg',
        default: 'gen045.svg'
    }

    return icons[type] || icons['default'];
}
export const Alert: React.FC<Props>= (props:Props) => {
    const {id,type,title,message,closable=true} = props;
    const icon = getIcon(type);
    const dispatch = useDispatch();


    const removeAlertHandle = () => {
        dispatch(removeAlert(id))
    }
    return (
        <div className='row'>
            <div className='col-12'>
                <div className={`alert alert-dismissible d-flex flex-column flex-sm-row mb-lg-15 alert-dismissible fade show alert-${type}`}>
                    <KTSVG path={`/media/icons/duotune/general/${icon}`} className={`svg-icon svg-icon-2hx svg-icon-${type} me-4 mb-5 mb-sm-0`} />
                    <div className={`d-flex flex-column text-${type} pe-0 pe-sm-10`}>
                        <h5 className="mb-1">{title}</h5>
                        <span dangerouslySetInnerHTML={{ __html: message }}></span>
                    </div>
                    {closable && (
                        <button onClick={removeAlertHandle} type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                            <KTSVG path="/media/icons/duotune/arrows/arr011.svg" className={`svg-icon-2hx svg-icon-${type}`}  />
                        </button>
                    )}
                </div>
            </div>
        </div>
        )
}
