import {all} from 'redux-saga/effects'
import { combineReducers} from '@reduxjs/toolkit';

import * as auth from '../../app/modules/auth'
import * as alerts  from '../../app/modules/alerts';
import * as notifications  from '../../app/modules/notifications';
import * as revaluation  from '../../app/modules/revaluation';

export const rootReducer = combineReducers({
    auth: auth.authSlice.reducer,
    alert: alerts.alertSlice.reducer,
    notifications: notifications.notificationSlice.reducer,
    revaluationCountry: revaluation.revaluationCountrySlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
    yield all([auth.saga()])
}
