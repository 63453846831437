/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/core/_redux'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import {UserModel} from "../../../../app/modules/users/core/_models";

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }
  const intl = useIntl()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <div className='menu-content d-flex align-items-center px-5'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{user.name}</div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          {intl.formatMessage({id: 'AUTH.GENERAL.ACCOUNT_SETTINGS'})}
        </Link>
      </div>

      <Languages />

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'AUTH.GENERAL.SIGNOUT_BUTTON'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
