/**
 * Converts array to an indexed object
 * @param init Entity array
 */
import {IdItem} from "./createEntityStruct";

export const toIndexedObject = <T extends IdItem>(init: T[]) => {
    const ret: { [id: string]: T; } = {};
    init.forEach((item) => {
        ret[item.id] = item;
    });
    return ret;
};
